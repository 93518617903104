'use client';

import { Box, Flex } from '@chakra-ui/react';
import { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { usePathname } from 'next/navigation';
import colors from '@/constants/theme/colors';
import Drawers from './Drawers';

import dynamic from 'next/dynamic';
import { pick } from 'lodash';
import { ILayoutSsr } from '@/types/app';

const Footer = dynamic(() => import('./Footer/Footer'));
const AppInit = dynamic(() => import('./AppInit'), {
  ssr: false,
});
const Modals = dynamic(() => import('./Modals/Modals'));
const Header = dynamic(() => import('./Header/Header'), {
  loading: () => <Box w="100%" h={{ base: '3.5rem', xl: '5rem' }}></Box>,
});
const Banner = dynamic(() => import('./Header/Banner'), {
  ssr: false,
  loading: () => <Box w="100%" bgColor={colors.primary[100]} h="2rem"></Box>,
});

export interface LayoutProps extends PropsWithChildren {
  ssr: ILayoutSsr;
  token?: string;
  role?: string;
  authStatus?: string;
  isDeviceMobile?: boolean;
}

const Layout: FC<LayoutProps> = ({
  children,
  token,
  role,
  authStatus,
  ssr,
}) => {
  const pathname = usePathname();
  const pageRef = useRef<HTMLDivElement>(null);

  const { banners = [], faqs = [], footer } = ssr;
  const headerProps = pick(ssr, [
    'shopMenuThumbnail',
    'giftMenuThumbnail',
    'upgradeMenuThumbnail',
    'bespokeMenu',
  ]);

  useEffect(() => {
    if (!window) return;
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Drawers />
      <Modals />
      <AppInit token={token} role={role} authStatus={authStatus} />
      <Flex
        flexDir="column"
        alignItems="center"
        maxW="100vw"
        ref={pageRef}
        bgColor={colors.background}
      >
        {/* mobile: 3.5rem + 2rem */}
        {/* desktop: 5rem + 2rem */}
        <Box w="100%" h={{ base: '5.5rem', xl: '7rem' }}>
          <Header {...headerProps}>
            <Flex w="100%" flexDir="column" minH="2rem">
              {banners.map(({ textToDisplay }, idx) => (
                <Banner key={`${textToDisplay}_${idx}`} texts={textToDisplay} />
              ))}
            </Flex>
          </Header>
        </Box>
        <Box flex="1" width="100%">
          {children}
        </Box>
        {/* Note: remove animation on navigating */}
        <Footer footer={footer} faqs={faqs} />
      </Flex>
    </>
  );
};

export default Layout;
