import { IPublicCartCheckoutResponse } from '@/store/apis/app.carts';
import { ICartItem } from '@/types/cart';
import { reduce } from 'lodash';

export const getSubTotalPriceFromCartItems: (
  cartItems: ICartItem[]
) => number = (cartItems) => {
  const value = reduce(
    cartItems,
    (total, item) => {
      const { quantity, subTotalPrice, productVariant } = item;
      return (subTotalPrice ?? productVariant.price * quantity) + total;
    },
    0
  );

  return value;
};

export const getCurrencyPriceFromCartItems: (
  cartItems: ICartItem[]
) => string = (cartItems) => {
  if (!cartItems.length) return '';

  return cartItems[0].productVariant.priceCurrency;
};

export const PUBLIC_CART_FIELDS: (keyof IPublicCartCheckoutResponse)[] = [
  'stripeClientSecret',
  'stripePaymentIntentId',
  'shippingPrice',
  'code',
  'giftCardCode',
  'couponCode',
  'shippingPrice',
  'cartItems',
];
