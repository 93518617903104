import appApis, { APP_QUERY_TAG } from '@/store/apis/app';
import { ConfigKey, IConfig } from '@/types/api/app/config';
import { Dictionary, groupBy, keyBy, last, mapValues, sortBy } from 'lodash';

const appConfigsApis = appApis.injectEndpoints({
  endpoints: (build) => ({
    getConfigValues: build.query<Dictionary<string>, void>({
      query: () => ({
        url: `/configs/latest-public`,
      }),
      providesTags: [APP_QUERY_TAG.config],
      transformResponse: (data: IConfig[] = []) => {
        const configGroup = groupBy(
          data.filter((c) => c.key === ConfigKey.AppSettings),
          (c) => c.name
        );
        const latestConfig = Object.values(configGroup).map((valueList) => {
          return last(sortBy(valueList, 'createdAt'))!;
        });
        const configMap = keyBy(latestConfig, 'name');

        const configValues = mapValues(configMap, 'value');

        return configValues;
      },
    }),
  }),
});

export const { useGetConfigValuesQuery } = appConfigsApis;

export default appConfigsApis;
