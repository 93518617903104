import(/* webpackMode: "eager" */ "/app/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/Layout/LayoutWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"fonts/index.tsx\",\"import\":\"Inter\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"fonts/index.tsx\",\"import\":\"Karla\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"200\",\"300\",\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"bodyFont\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"fonts/index.tsx\",\"import\":\"Noto_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"500\"]}],\"variableName\":\"notoSans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"fonts/index.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./PolySans Slim.ttf\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"./PolySans Neutral.ttf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./PolySans Median.ttf\",\"weight\":\"600\",\"style\":\"normal\"}],\"variable\":\"--polySans-font\"}],\"variableName\":\"titleFont\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"fonts/index.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./PolySans Slim Wide.ttf\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"./PolySans Neutral Wide.ttf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./PolySans Median Wide.ttf\",\"weight\":\"600\",\"style\":\"normal\"}]}],\"variableName\":\"polySansWide\"}");
