import {
  ComponentStyleConfig,
  SystemStyleInterpolation,
} from '@chakra-ui/react';
import colors from '@/constants/theme/colors';

const desktopVariant: SystemStyleInterpolation = {
  header: {
    p: '0',
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: '400',
    lineHeight: '2rem',
    color: colors.primary[300],
  },
  overlay: {
    bgColor: 'rgba(0, 0, 0, 0.60)',
  },
  dialogContainer: {},
  dialog: {
    p: '0 1.5rem 2.5rem',
    pt: '3.5rem',
    gap: '2.5rem',
    borderRadius: 'none',
    boxShadow: '0px 4px 10px 4px rgba(0, 0, 0, 0.16)',
    w: '28.75rem',
    maxW: '100%',
    h: 'auto',
  },
  closeButton: {
    top: '1.5rem',
    right: '1.5rem',
    w: '1.5rem',
    h: '1.5rem',
    color: colors.primary[100],
  },
  body: {
    p: '0',
    maxH: {
      base: '25.5rem',
      md: 'none',
    },
    overflowY: 'auto',
  },
  footer: {
    p: '0',
    fontSize: '0.875rem',
    fontWeight: '400',
    lineHeight: '1.25rem',
  },
};

const mobileVariant: SystemStyleInterpolation = {
  header: {
    p: '0 0.25rem',
    textAlign: 'center',

    fontSize: '1.125rem',
    fontWeight: '400',
    lineHeight: '1.5rem',
  },
  overlay: {
    bgColor: 'rgba(0, 0, 0, 0.60)',
  },
  dialogContainer: {
    px: '2.2rem',
  },
  dialog: {
    p: '1.5rem 1rem',
    gap: '1rem',
    w: '100%',
    borderRadius: 'none',
    boxShadow: '0px 4px 10px 4px rgba(0, 0, 0, 0.16)',
  },
  closeButton: {
    top: '1.5rem',
    right: '1rem',
    w: '1.5rem',
    h: '1.5rem',
    color: colors.primary[100],
  },
  body: {
    p: '0',
    maxH: '25.5rem',
    overflowY: 'auto',
  },
  footer: {
    p: '0',
    fontSize: '0.875rem',
    fontWeight: '400',
    lineHeight: '1.25rem',
  },
};

const mobileFullScreenVariant: SystemStyleInterpolation = {
  header: {
    p: '0 0.25rem',
    textAlign: 'center',

    fontSize: '1.125rem',
    fontWeight: '400',
    lineHeight: '1.5rem',
  },
  overlay: {
    bgColor: 'rgba(0, 0, 0, 0.60)',
  },
  dialogContainer: {},
  dialog: {
    p: '0 1rem 1.5rem',
    pt: 'calc(1.5rem + 2rem)',
    gap: '1.5rem',
    borderRadius: 'none',
    w: '100vw',
    h: '100dvh',
    maxW: 'none',
  },
  closeButton: {
    top: '1.5rem',
    right: '1rem',
    w: '1.5rem',
    h: '1.5rem',
    color: colors.primary[100],
  },
  body: {
    p: '0',
    flex: 'none',
  },
  footer: {
    p: '0',
    fontSize: '0.875rem',
    fontWeight: '400',
    lineHeight: '1.25rem',
  },
};

const pricingVariant: SystemStyleInterpolation = {
  overlay: {
    bgColor: 'rgba(0, 0, 0, 0.60)',
  },
  dialogContainer: {},
  dialog: {
    borderRadius: 'none',
    w: { base: '100vw', xl: '48rem' },
    h: { base: '100vh', xl: 'max-content' },
    maxW: 'none',
    maxH: '100vh',
  },
  closeButton: {
    top: { base: '1rem', xl: '1.25rem' },
    right: { base: '0.75rem', xl: '1rem' },
    w: '1.5rem',
    h: '1.5rem',
    color: colors.primary[100],
  },
  body: {
    px: { base: '0.75rem', xl: '1rem' },
    pt: { base: '0.75rem', xl: '1rem' },
    pb: { base: '2rem', xl: '1rem' },
    bgColor: colors.background,
  },
  footer: {
    p: '0',
  },
};

const ModalStyles: ComponentStyleConfig = {
  baseStyle: {
    header: {},
    overlay: {},
    dialogContainer: {},
    dialog: {},
    closeButton: {},
    body: {},
    footer: {},
  },
  variants: {
    desktop: desktopVariant,
    mobile: mobileVariant,
    mobileFullScreen: mobileFullScreenVariant,
    pricing: pricingVariant,
  },
  defaultProps: {
    variant: { base: 'mobile', xl: 'desktop' },
    isCentered: true,
  },
};

export default ModalStyles;
