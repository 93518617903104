import {
  NEXT_PUBLIC_SANITY_PROJECT_ID,
  NEXT_PUBLIC_SANITY_API_VERSION,
  NEXT_PUBLIC_SANITY_DATASET,
} from '@/config/sanity';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';

import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import { ImageLoader } from 'next/image';

export const sanityClient = createClient({
  projectId: NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: NEXT_PUBLIC_SANITY_DATASET,
  apiVersion: NEXT_PUBLIC_SANITY_API_VERSION,
  useCdn: true,
});

const builder = imageUrlBuilder(sanityClient);
export const urlFor = (source: SanityImageSource) => builder.image(source);

// <projectId>.api.sanity.io/<version>/<path>
// https://zp7mbokg.api.sanity.io/v2021-06-07/data/query/production
export const getSanityApiUrl = () => {
  return `https://${NEXT_PUBLIC_SANITY_PROJECT_ID}.api.sanity.io/v${NEXT_PUBLIC_SANITY_API_VERSION}/data/query/${NEXT_PUBLIC_SANITY_DATASET}`;
};

// Docs: https://www.sanity.io/docs/image-urls
export const sanityLoader: ImageLoader = ({ src, width, quality }) => {
  const isSanityUrl = src.includes('sanity');
  if (!isSanityUrl) return src;

  const url = new URL(`${src}`);
  url.searchParams.set('auto', 'format');
  url.searchParams.set('fit', 'max');
  url.searchParams.set('w', width.toString());
  if (quality) {
    url.searchParams.set('q', quality.toString());
  }
  return url.href;
};
