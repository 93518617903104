'use client';

import {
  Flex,
  Box,
  Text,
  Tooltip,
  FlexProps,
  TextProps,
  Skeleton,
  Modal,
  ModalProps,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Button,
  useDisclosure,
  Spinner,
} from '@chakra-ui/react';
import { FC, useCallback, useEffect, useState } from 'react';
import Image from 'next/image';
import { ICartItem } from '@/types/cart';
import { LockLineIcon } from '@/components/Icons';
import colors from '@/constants/theme/colors';
import { SubtractLineIcon, AddLineIcon } from '@/components/Icons';
import { useDispatch } from 'react-redux';
import {
  appActions,
  useCurrencyCodeSelector,
  useCurrencyExchangeRateSelector,
  usePublicCartSelector,
} from '@/store/slices/app';
import { displayProductVariantMetal, formatPrice } from '@/utils/product';
import {
  IPostCartCheckoutReqBody,
  useAddCartItemMutation,
  useCheckoutCartMutation,
  useCheckoutPublicCartMutation,
  useDeleteCartItemMutation,
  useGetCartQuery,
} from '@/store/apis/app.carts';
import { useGetGiftCardQuery } from '@/store/apis/app.products';
import { GIFTCARD_IMAGE_URL, GiftCardType } from '@/utils/gift-card';
import useToast from '@/hooks/useToast';
import { useGetLoggedCustomer } from '@/store/apis/app';
import { skipToken } from '@reduxjs/toolkit/query';
import { usePathname, useRouter } from 'next/navigation';
import { PATH } from '@/constants/paths';
import { filter, map, pick, uniqBy } from 'lodash';
import { IAppCartItem } from '@/types/api/app/cart';
import { PUBLIC_CART_FIELDS } from '@/utils/cart';
import { useGetSanityDialogsQuery } from '@/store/apis/sanity';

const UPDATE_ITEM_ERROR = 'Failed to update cart item. Please try again.';

interface CartItemProps {
  cartItem: ICartItem;
}
const CartItem: FC<CartItemProps> = ({ cartItem }) => {
  const {
    product,
    productVariant,
    quantity,
    subTotalPrice,
    id: cartItemId,
    metadata,
  } = cartItem;

  const router = useRouter();
  const pathname = usePathname();
  const toast = useToast();
  const dispatch = useDispatch();
  const selectedCurrencyCode = useCurrencyCodeSelector();
  const currencyExchangeRate = useCurrencyExchangeRateSelector();

  const [addCartItem] = useAddCartItemMutation();
  const [deleteCartItem] = useDeleteCartItemMutation();
  const [checkoutPublicCart] = useCheckoutPublicCartMutation();
  const [checkoutCart] = useCheckoutCartMutation();
  const { data: giftCard } = useGetGiftCardQuery();
  const { loggedCustomer } = useGetLoggedCustomer();
  const { data: storedCart } = useGetCartQuery(
    !!loggedCustomer ? undefined : skipToken
  );
  const publicCart = usePublicCartSelector();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const [isLoading, setIsLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState<{
    onConfirm?: () => void;
  } | null>(null);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const {
    price,
    priceCurrency,
    metal,
    size,
    photoOrVideoUrls,
    availableQuantity,
  } = productVariant ?? {};

  const currencyValue = selectedCurrencyCode ?? priceCurrency;
  const priceValue = (subTotalPrice ?? price * quantity) * currencyExchangeRate;

  const incQtyLoggedUser = useCallback(
    async (qty: number) => {
      await addCartItem({
        body: {
          id: cartItemId,
          productVariantId: productVariant.id,
          quantity: qty,
          metadata,
        },
      }).unwrap();
      dispatch(
        appActions.incCartItemQty({
          cartProductItem: {
            product,
            productVariant,
            metadata,
          },
        })
      );
    },
    [addCartItem, metadata, product, productVariant, cartItemId, dispatch]
  );

  const incQtyPublicUser = useCallback(
    async (qty: number) => {
      setIsLoading(true);
      if (!!publicCart) {
        const cartItems = publicCart.cartItems;

        const newCartItems = uniqBy(
          map(cartItems, (it) => {
            const matched = it.productVariantId === productVariant.id;
            if (!matched) return it;

            return {
              ...it,
              quantity: qty,
              subTotalPrice: (it.subTotalPrice / it.quantity) * qty,
            };
          }),
          (it) => it.productVariantId
        );

        const newPublicCart = pick(publicCart, PUBLIC_CART_FIELDS);

        const res = await checkoutPublicCart({
          ...newPublicCart,
          cartItems: newCartItems,
        }).unwrap();
        const { stripeClientSecret, stripePaymentIntentId } = res;

        if (!stripeClientSecret || !stripePaymentIntentId) {
          toast(UPDATE_ITEM_ERROR, 'warning');
        }
        dispatch(appActions.setStripePaymentClientSecret(stripeClientSecret));
        dispatch(appActions.setStripePaymentIntentId(stripePaymentIntentId));
      }

      dispatch(
        appActions.incCartItemQty({
          cartProductItem: {
            product,
            productVariant,
            metadata,
          },
        })
      );
      setIsLoading(false);
    },
    [
      publicCart,
      productVariant,
      checkoutPublicCart,
      dispatch,
      metadata,
      product,
      toast,
    ]
  );

  const handleIncQty = useCallback(async () => {
    try {
      const newQty = quantity + 1;
      if (newQty > availableQuantity) {
        toast(`This items only have ${availableQuantity} left.`, 'warning');
        return;
      }

      if (!!loggedCustomer) {
        await incQtyLoggedUser(newQty);
        return;
      }

      await incQtyPublicUser(newQty);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  }, [
    quantity,
    loggedCustomer,
    availableQuantity,
    incQtyLoggedUser,
    incQtyPublicUser,
    toast,
  ]);

  const decQtyLoggedUser = useCallback(
    async (qty: number) => {
      if (!storedCart?.cartItems) {
        toast(UPDATE_ITEM_ERROR, 'warning');
        return;
      }

      setIsLoading(true);
      if (!qty) {
        const { cartItems } = storedCart;
        const isLastItem =
          cartItems.length === 1 && cartItems[0].id === cartItemId;

        if (isLastItem) {
          setConfirmModal({
            onConfirm: async () => {
              setIsConfirmLoading(true);
              if (pathname.includes('checkout')) {
                router.push(PATH.home);
              }
              setTimeout(async () => {
                await deleteCartItem({
                  body: {},
                  params: {
                    cartItemId,
                  },
                });
                dispatch(
                  appActions.decCartItemQty({
                    cartProductItem: { product, productVariant, metadata },
                  })
                );
                setIsConfirmLoading(false);
              }, 500);
            },
          });
          onOpen();
          setIsLoading(false);
          return;
        }

        await deleteCartItem({
          body: {},
          params: {
            cartItemId,
          },
        }).unwrap();
        // to refresh loggedCart on checking out
        if (pathname.includes('checkout')) {
          await checkoutCart({});
        }
      }
      // !!qty
      else {
        await addCartItem({
          body: {
            id: cartItemId,
            productVariantId: productVariant.id,
            quantity: qty,
            metadata,
          },
        }).unwrap();
      }

      dispatch(
        appActions.decCartItemQty({
          cartProductItem: { product, productVariant, metadata },
        })
      );
      setIsLoading(false);
    },
    [
      addCartItem,
      deleteCartItem,
      storedCart,
      cartItemId,
      checkoutCart,
      dispatch,
      metadata,
      onOpen,
      pathname,
      product,
      productVariant,
      router,
      toast,
    ]
  );

  const decQtyPublicUser = useCallback(
    async (qty: number) => {
      setIsLoading(true);
      if (!!publicCart) {
        const cartItems = publicCart.cartItems;

        let newCartItems: IAppCartItem[] = [];

        if (!!qty) {
          newCartItems = uniqBy(
            map(cartItems, (it) => {
              const matched = it.productVariantId === productVariant.id;
              if (!matched) return it;

              return {
                ...it,
                quantity: qty,
                subTotalPrice: (it.subTotalPrice / it.quantity) * qty,
              };
            }),
            (it) => it.productVariantId
          );
        }
        // !qty
        else {
          const isLastItem =
            cartItems.length === 1 &&
            cartItems[0].productVariantId === productVariant.id;

          if (isLastItem) {
            setConfirmModal({
              onConfirm: async () => {
                setIsConfirmLoading(true);
                if (pathname.includes('checkout')) {
                  router.push(PATH.home);
                }
                setTimeout(async () => {
                  dispatch(appActions.setPublicCart(null));
                  dispatch(
                    appActions.decCartItemQty({
                      cartProductItem: { product, productVariant, metadata },
                    })
                  );
                  setIsConfirmLoading(false);
                }, 500);
              },
            });
            onOpen();
            setIsLoading(false);
            return;
          }

          newCartItems = filter(
            cartItems,
            (it) => it.productVariantId !== productVariant.id
          );
        }

        const newPublicCart = pick(publicCart, PUBLIC_CART_FIELDS);

        await checkoutPublicCart({
          ...newPublicCart,
          cartItems: newCartItems,
        }).unwrap();
      }

      dispatch(
        appActions.decCartItemQty({
          cartProductItem: { product, productVariant, metadata },
        })
      );
      setIsLoading(false);
    },
    [
      publicCart,
      productVariant,
      checkoutPublicCart,
      dispatch,
      metadata,
      onOpen,
      pathname,
      product,
      router,
    ]
  );

  const handleDecQty = useCallback(async () => {
    try {
      const newQty = quantity - 1;
      if (!!loggedCustomer) {
        await decQtyLoggedUser(newQty);
        return;
      }
      await decQtyPublicUser(newQty);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  }, [quantity, loggedCustomer, decQtyLoggedUser, decQtyPublicUser]);

  useEffect(() => {
    setIsLoading(false);
  }, [subTotalPrice]);

  const metalValue = !!metal ? displayProductVariantMetal(metal).value : '';
  const isGiftCard = !!giftCard && giftCard.id === product.id;

  const _renderImage = () => {
    if (isGiftCard) {
      const { size: giftCardType } = productVariant as { size: GiftCardType };

      const imageUrl = GIFTCARD_IMAGE_URL[giftCardType];

      return (
        <Box w="72px" h="72px" position="relative" overflow="hidden">
          <Image
            alt="product"
            src={imageUrl}
            fill
            style={{
              objectFit: 'cover',
            }}
          />
        </Box>
      );
    }

    if (!!photoOrVideoUrls?.[0])
      return (
        <Box w="72px" h="72px" position="relative" overflow="hidden">
          <Image
            alt="product"
            fill
            style={{ objectFit: 'cover' }}
            src={photoOrVideoUrls[0]}
            unoptimized
          />
        </Box>
      );

    return (
      <Flex
        w="72px"
        h="72px"
        alignItems="center"
        justifyContent="center"
        bgColor={colors.grey[100]}
      >
        <Text
          color={colors.primary[300]}
          textTransform="uppercase"
          fontSize="0.675rem"
        >
          no image
        </Text>
      </Flex>
    );
  };

  const _renderTitle = () => {
    const props: TextProps = {
      color: colors.primary[300],
      lineHeight: '1.5rem',
      maxW: { base: '60vw', lg: '12rem' },
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    };

    if (isGiftCard) {
      const { size: giftCardType } = productVariant as { size: GiftCardType };

      const title = `${giftCardType} Gift Card`;

      return <Text {...props}>{title}</Text>;
    }

    const { title } = product;
    const { title: variantTitle } = productVariant;

    return <Text {...props}>{variantTitle ?? title}</Text>;
  };

  const _renderSubtitle = () => {
    const props: TextProps = {
      color: colors.grey[500],
      fontSize: '0.875rem',
      fontWeight: '300',
      lineHeight: '1.25rem',
      display: 'flex',
    };

    if (isGiftCard) {
      if (!metadata) return null;
      const {
        recipientEmail = '',
        recipientName = '',
        message = '',
      } = metadata;

      return (
        <Flex flexDir="column" gap="0.5rem">
          <Text {...props}>{`${recipientName} (${recipientEmail})`}</Text>
          <Text {...props}>{`"${message}"`}</Text>
        </Flex>
      );
    }

    return (
      <Text {...props}>{`${metalValue}${!!size ? `, ${size}` : ''}`}</Text>
    );
  };

  return (
    <>
      <EmptyCartConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        isConfirmLoading={isConfirmLoading}
        {...confirmModal}
      />
      <Flex gap="1rem">
        <Box
          position="relative"
          width="4.5rem"
          aspectRatio="1/1"
          overflow="hidden"
        >
          {_renderImage()}
        </Box>
        <Flex flexDir="column" gap="0.5rem" flex="1">
          <Flex flexDir="column">
            {_renderTitle()}
            {_renderSubtitle()}
          </Flex>
          <Flex alignItems="center" justifyContent="space-between">
            <Box>
              <QuantityInput
                isLoading={isLoading}
                quantity={quantity}
                onInc={handleIncQty}
                onDec={handleDecQty}
              />
            </Box>
            <Tooltip
              hasArrow
              placement="bottom-start"
              label="Price locked while in cart, check out within 15 mins to secure the locked price."
            >
              <Flex alignItems="center" gap="0.25rem" cursor="pointer">
                <Skeleton isLoaded={!isLoading}>
                  <Text
                    color={colors.primary[300]}
                    fontSize="0.9375rem"
                    lineHeight="1.25rem"
                  >
                    {`${formatPrice(currencyValue)(
                      priceValue,
                      undefined,
                      undefined,
                      2
                    )}`}
                  </Text>
                </Skeleton>
                <LockLineIcon size="1rem" color={colors.primary[25]} />
              </Flex>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

interface EmptyCartConfirmModalProps
  extends Pick<ModalProps, 'isOpen' | 'onClose'> {
  onConfirm?: () => void;
  isConfirmLoading?: boolean;
}
const EmptyCartConfirmModal: FC<EmptyCartConfirmModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  isConfirmLoading,
}) => {
  const { data, isLoading, isFetching } = useGetSanityDialogsQuery();
  const showLoading = isLoading || isFetching;
  const lastCartItemDialog = data?.lastCartItemDialog;

  const title = lastCartItemDialog?.title;
  const description = lastCartItemDialog?.description;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {showLoading && (
          <ModalBody>
            <Flex w="100%" justify="center" align="center" h="3rem">
              <Spinner />
            </Flex>
          </ModalBody>
        )}
        {!showLoading && (
          <ModalBody>
            <Flex flexDir="column" gap="1rem" align="center">
              <Flex flexDir="column" gap="0.5rem">
                <Text
                  textAlign="center"
                  fontSize={{ base: '1.25rem', xl: '1.5rem' }}
                  lineHeight={{ base: '1.5rem', xl: '2rem' }}
                  color={colors.primary[300]}
                >
                  {title}
                </Text>
                <Text
                  textAlign="center"
                  fontSize={{ base: '0.875rem', xl: '1rem' }}
                  lineHeight={{ base: '1.25rem', xl: '1.5rem' }}
                  color={colors.grey[600]}
                >
                  {description}
                </Text>
              </Flex>
              <Flex alignItems="center" gap="0.5rem" w="100%">
                <Button
                  flex="1"
                  variant="primaryMd"
                  onClick={onConfirm}
                  isLoading={isConfirmLoading}
                >
                  Delete
                </Button>
                <Button
                  flex="1"
                  variant="secondaryMd"
                  onClick={onClose}
                  isDisabled={isConfirmLoading}
                >
                  Cancel
                </Button>
              </Flex>
            </Flex>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
};

interface QuantityInputProps {
  quantity: number;
  onInc?: () => void;
  onDec?: () => void;
  isLoading?: boolean;
}
const QuantityInput: FC<QuantityInputProps> = ({
  quantity,
  isLoading,
  onInc,
  onDec,
}) => {
  const actionContainerProps: FlexProps = {
    alignItems: 'center',
    justifyContent: 'center',
    p: '0.25rem',
    cursor: isLoading ? 'not-allowed' : 'pointer',
  };

  return (
    <Flex
      w="fit-content"
      border={`1px solid ${colors.grey[200]}`}
      userSelect="none"
    >
      <Flex
        {...actionContainerProps}
        borderRight={`1px solid ${colors.grey[200]}`}
        onClick={() => {
          if (isLoading) return;
          onDec?.();
        }}
      >
        <SubtractLineIcon size="0.75rem" color={colors.primary[300]} />
      </Flex>
      <Flex alignItems="center" justifyContent="center">
        <Skeleton isLoaded={!isLoading}>
          <Text
            color={colors.primary[300]}
            fontSize="0.75rem"
            fontWeight="300"
            lineHeight="1rem"
            px="0.75rem"
          >
            {quantity}
          </Text>
        </Skeleton>
      </Flex>
      <Flex
        {...actionContainerProps}
        borderLeft={`1px solid ${colors.grey[200]}`}
        onClick={() => {
          if (isLoading) return;
          onInc?.();
        }}
      >
        <AddLineIcon size="0.75rem" color={colors.primary[300]} />
      </Flex>
    </Flex>
  );
};

export default CartItem;
