if (!process.env.NEXT_PUBLIC_SANITY_TOKEN) {
  throw new Error('Missing NEXT_PUBLIC_SANITY_TOKEN in .env file');
}

if (!process.env.NEXT_PUBLIC_SANITY_PROJECT_ID) {
  throw new Error('Missing NEXT_PUBLIC_SANITY_PROJECT_ID in .env file');
}

if (!process.env.NEXT_PUBLIC_SANITY_DATASET) {
  throw new Error('Missing NEXT_PUBLIC_SANITY_DATASET in .env file');
}

if (!process.env.NEXT_PUBLIC_SANITY_API_VERSION) {
  throw new Error('Missing NEXT_PUBLIC_SANITY_API_VERSION in .env file');
}

const NEXT_PUBLIC_SANITY_TOKEN = process.env.NEXT_PUBLIC_SANITY_TOKEN ?? '';
const NEXT_PUBLIC_SANITY_PROJECT_ID =
  process.env.NEXT_PUBLIC_SANITY_PROJECT_ID ?? '';
const NEXT_PUBLIC_SANITY_DATASET = process.env.NEXT_PUBLIC_SANITY_DATASET ?? '';
const NEXT_PUBLIC_SANITY_API_VERSION =
  process.env.NEXT_PUBLIC_SANITY_API_VERSION ?? '';

export {
  NEXT_PUBLIC_SANITY_TOKEN,
  NEXT_PUBLIC_SANITY_PROJECT_ID,
  NEXT_PUBLIC_SANITY_DATASET,
  NEXT_PUBLIC_SANITY_API_VERSION,
};
