import {
  ComponentStyleConfig,
  SystemStyleInterpolation,
} from '@chakra-ui/react';

import colors from '@/constants/theme/colors';

const commonStyleLg: SystemStyleInterpolation = {
  h: 'fit-content',
  p: '1rem 1.5rem',
  fontWeight: '400',
  fontSize: '1.25rem',
  lineHeight: '1.5rem',
  minW: '5.5rem',
  minH: '3.5rem',
};

const commonStyleMd: SystemStyleInterpolation = {
  h: 'fit-content',
  padding: '0.75rem',
  fontWeight: '400',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  minW: '4.625rem',
  minH: '3rem',
};

const commonStyleSm: SystemStyleInterpolation = {
  h: 'fit-content',
  p: '0.625rem 0.75rem',
  fontWeight: '400',
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  minW: '4.25rem',
  minH: '2.5rem',
};

const commonStyleIconLg: SystemStyleInterpolation = {
  w: '3.5rem',
  h: '3.5rem',
};

const commonStyleIconMd: SystemStyleInterpolation = {
  w: '3rem',
  h: '3rem',
};

const commonStylePrimary: SystemStyleInterpolation = {
  bgColor: colors.primary[100],
  borderRadius: 'none',
  border: `1px solid ${colors.primary[100]}`,
  color: colors.white,
  _disabled: {
    bgColor: colors.primary[100],
    borderColor: colors.primary[100],
    opacity: 0.5,
  },
  _hover: {
    bgColor: colors.primary[300],
    _disabled: {
      bgColor: colors.primary[100],
      opacity: 0.5,
    },
  },
  _active: {
    bgColor: colors.primary[50],
  },
  _loading: {},
};

const commonStyleTertiary: SystemStyleInterpolation = {
  bgColor: 'transparent',
  borderRadius: 'none',
  border: 'none',
  color: colors.primary[100],
  textDecor: 'underline',
  _disabled: {
    opacity: 0.5,
  },
  _hover: {
    _disabled: {
      opacity: 0.5,
    },
  },
  _active: {},
  _loading: {},
};

const commonStyleSecondary: SystemStyleInterpolation = {
  borderRadius: 'none',
  border: `1px solid ${colors.primary[100]}`,
  color: colors.primary[100],
  _disabled: {
    border: `1px solid ${colors.primary[100]}`,
    opacity: 0.5,
  },
  _hover: {
    border: `1px solid ${colors.primary[300]}`,
    _disabled: {
      border: `1px solid ${colors.primary[100]}`,
      opacity: 0.5,
    },
  },
  _active: {
    bgColor: colors.primary[25],
  },
};

const commonStyleDark: SystemStyleInterpolation = {
  bgColor: 'transparent',
  borderRadius: 'none',
  border: `1px solid ${colors.white}`,
  color: colors.white,
  _disabled: {
    color: colors.white,
    borderColor: colors.white,
    opacity: 0.5,
  },
  _hover: {
    color: colors.white,
    _disabled: {
      color: colors.white,
      borderColor: colors.white,
      opacity: 0.5,
    },
  },
  _active: {
    border: '1px solid rgba(255, 255, 255, 0.80)',
  },
};

const commonStyleGhost: SystemStyleInterpolation = {
  bgColor: 'transparent',
  borderRadius: 'none',
  border: `1px solid ${colors.primary[100]}`,
  _disabled: {
    color: colors.primary[50],
    borderColor: colors.primary[25],
  },
  _hover: {
    color: colors.white,
    bgColor: colors.primary[100],
    _disabled: {
      color: colors.primary[50],
      borderColor: colors.primary[25],
    },
  },
  _active: {
    color: colors.white,
    bgColor: colors.primary[200],
  },
};

const commonStyleTextPrimary: SystemStyleInterpolation = {
  p: '0',
  minH: 'none',
  h: 'fit-content',
  borderRadius: 'none',
  color: colors.primary[100],
  _disabled: {
    color: colors.grey[400],
    boxShadow: '0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.25)',
  },
  _hover: {
    color: colors.primary[100],
    _disabled: {
      color: colors.grey[400],
      boxShadow: '0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.25)',
    },
  },
  _active: {
    color: colors.primary[200],
  },
};

const commonStyleTextSecondary: SystemStyleInterpolation = {
  p: '0',
  minH: 'none',
  h: 'fit-content',
  color: colors.grey[800],
  _disabled: {
    color: colors.grey[400],
    boxShadow: '0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.25)',
  },
  _hover: {
    color: colors.grey[600],
    _disabled: {
      color: colors.grey[400],
      boxShadow: '0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.25)',
    },
  },
  _active: {
    color: colors.grey[700],
  },
};

const commonStyleTextDark: SystemStyleInterpolation = {
  p: '0',
  borderRadius: 'none',
  color: colors.white,
  // TODO: confirm on them of text dark button
  // borderBottom: `1px solid ${colors.white}`,
  _disabled: {
    opacity: 0.5,
    boxShadow: '0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.25)',
  },
  _hover: {
    opacity: 0.6,
    _disabled: {
      opacity: 0.5,
      boxShadow: '0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.25)',
    },
  },
  _active: {
    opacity: 0.8,
  },
};

const primaryLgVariant: SystemStyleInterpolation = {
  ...commonStyleLg,
  ...commonStylePrimary,
};
const primaryMdVariant: SystemStyleInterpolation = {
  ...commonStyleMd,
  ...commonStylePrimary,
};
const primarySmVariant: SystemStyleInterpolation = {
  ...commonStyleSm,
  ...commonStylePrimary,
};

const secondaryLgVariant: SystemStyleInterpolation = {
  ...commonStyleLg,
  ...commonStyleSecondary,
};
const secondaryMdVariant: SystemStyleInterpolation = {
  ...commonStyleMd,
  ...commonStyleSecondary,
};
const secondarySmVariant: SystemStyleInterpolation = {
  ...commonStyleSm,
  ...commonStyleSecondary,
};

const ghostLgVariant: SystemStyleInterpolation = {
  ...commonStyleLg,
  ...commonStyleGhost,
};
const ghostMdVariant: SystemStyleInterpolation = {
  ...commonStyleMd,
  ...commonStyleGhost,
};
const ghostSmVariant: SystemStyleInterpolation = {
  ...commonStyleSm,
  ...commonStyleGhost,
};

const darkLgVariant: SystemStyleInterpolation = {
  ...commonStyleLg,
  ...commonStyleDark,
};
const darkMdVariant: SystemStyleInterpolation = {
  ...commonStyleMd,
  ...commonStyleDark,
};
const darkSmVariant: SystemStyleInterpolation = {
  ...commonStyleSm,
  ...commonStyleDark,
};

const tertiarySmVariant: SystemStyleInterpolation = {
  ...commonStyleSm,
  ...commonStyleTertiary,
};

const textPrimaryMdVariant: SystemStyleInterpolation = {
  ...commonStyleMd,
  ...commonStyleTextPrimary,
};
const textPrimarySmVariant: SystemStyleInterpolation = {
  ...commonStyleSm,
  ...commonStyleTextPrimary,
};

const textSecondaryMdVariant: SystemStyleInterpolation = {
  ...commonStyleMd,
  ...commonStyleTextSecondary,
};
const textSecondarySmVariant: SystemStyleInterpolation = {
  ...commonStyleSm,
  ...commonStyleTextSecondary,
};

const textDarkMdVariant: SystemStyleInterpolation = {
  ...commonStyleMd,
  ...commonStyleTextDark,
};
const textDarkSmVariant: SystemStyleInterpolation = {
  ...commonStyleSm,
  ...commonStyleTextDark,
};

const iconPrimaryLgVariant: SystemStyleInterpolation = {
  ...commonStylePrimary,
  ...commonStyleIconLg,
  borderRadius: '50%',
};
const iconPrimaryMdVariant: SystemStyleInterpolation = {
  ...commonStyleIconMd,
  ...commonStylePrimary,
  borderRadius: '50%',
};

const iconSecondaryLgVariant: SystemStyleInterpolation = {
  ...commonStyleIconLg,
  ...commonStyleSecondary,
  borderRadius: '50%',
};
const iconSecondaryMdVariant: SystemStyleInterpolation = {
  ...commonStyleIconMd,
  ...commonStyleSecondary,
  borderRadius: '50%',
};

const iconGhostLgVariant: SystemStyleInterpolation = {
  ...commonStyleIconLg,
  ...commonStyleGhost,
  borderRadius: '50%',
};
const iconGhostMdVariant: SystemStyleInterpolation = {
  ...commonStyleIconMd,
  ...commonStyleGhost,
  borderRadius: '50%',
};

const iconDarkLgVariant: SystemStyleInterpolation = {
  ...commonStyleIconLg,
  ...commonStyleDark,
  borderRadius: '50%',
};
const iconDarkMdVariant: SystemStyleInterpolation = {
  ...commonStyleIconMd,
  ...commonStyleDark,
  borderRadius: '50%',
};

const ButtonStyles: ComponentStyleConfig = {
  baseStyle: {},
  variants: {
    primaryLg: primaryLgVariant,
    primaryMd: primaryMdVariant,
    primarySm: primarySmVariant,

    secondaryLg: secondaryLgVariant,
    secondaryMd: secondaryMdVariant,
    secondarySm: secondarySmVariant,

    ghostLg: ghostLgVariant,
    ghostMd: ghostMdVariant,
    ghostSm: ghostSmVariant,

    darkLg: darkLgVariant,
    darkMd: darkMdVariant,
    darkSm: darkSmVariant,

    tertiarySm: tertiarySmVariant,

    iconPrimaryLg: iconPrimaryLgVariant,
    iconPrimaryMd: iconPrimaryMdVariant,

    iconSecondaryLg: iconSecondaryLgVariant,
    iconSecondaryMd: iconSecondaryMdVariant,

    iconGhostLg: iconGhostLgVariant,
    iconGhostMd: iconGhostMdVariant,

    iconDarkLg: iconDarkLgVariant,
    iconDarkMd: iconDarkMdVariant,
  },
  defaultProps: {
    variant: 'primarySm',
  },
};

export default ButtonStyles;
