import { IBaseEntity } from './common';

export enum ConfigKey {
  PriceVariables = 'price-variables',
  AppSettings = 'app-settings',
}

export enum ConfigName {
  // Price
  DesignCost = 'design-cost',
  StoneCost = 'stone-cost',
  PackagingCost = 'packaging-cost',
  OperationalCost = 'operational-cost',
  MarketingCost = 'marketing-cost',
  Markup = 'markup',

  // App settings
  LockTime = 'lock-time',
  GiftCardProductId = 'gift-card-product-id',
  ShippingFee = 'shipping-fee',
  ExpressShippingFee = 'express-shipping-fee',
  FreeShippingThreshold = 'free-shipping-threshold',
}

export interface IConfig extends IBaseEntity {
  key: ConfigKey;
  name: ConfigName;
  value: string;
  description?: string;
}
